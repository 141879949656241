import * as React from "react"
import { Link } from "gatsby"
import scrollTo from 'gatsby-plugin-smoothscroll'

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTop from "../components/pageTop"


import picSyachou from '../images/syacyou.jpg'
import picTorishimariyaku from '../images/torishimariyaku.jpg'
import picJyugyoin1 from '../images/jyugyouin1.jpg'
import picJyugyoin2 from '../images/jyugyouin2.jpg'
import picJyugyoin3 from '../images/jyugyouin3.jpg'

const About = () => {

	return (
  	<Layout>
      <Seo title="株式会社佐々畜産の会社概要" />
        <PageTop classname="about" title="会社概要" />

		<div id="main" className="about">

        <section className="index-section">
          <div className="content viewHeight100">
            <div className="centering-container">
              <table className="margin-center">
                <tr>
                  <td>名称</td><td>：</td><td>株式会社　佐々畜産</td>
                </tr>
                <tr>
                  <td>所在地</td><td>：</td><td>〒861-1302<br/>熊本県菊池市下河原鋤先2747</td>
                </tr>
                <tr>
                  <td>代表者</td><td>：</td><td>代表取締役　佐々隆文</td>
                </tr>
                <tr>
                  <td>従業員数</td><td>：</td><td>2名</td>
                </tr>
                <tr>
                  <td>事業内容</td><td>：</td><td>黒毛和種の繁殖および肥育<br/>肉用牛の診療（ルーメン家畜診療所）</td>
                </tr>
              </table>
              
              <div className="display-block margin-top30 text-center">
                <button type="button" className="material-icons btn-next cp_anime08" onClick={() => scrollTo('#keiei')}>expand_more</button>
              </div>
            </div>
          </div>
        </section>

        <section className="index-section padding-bottom100" id="keiei">
          <div className="centering-container margin-top50">
            <div className="centering-box">
              <h2 className="color-h2-text color-h2-border">目指す畜産経営</h2>
            </div>
            <p className="margin-bottom10">私が生まれた当時、両親はすでに牛を飼っており、365日休みなく忙しく働いていました。家族そろって旅行に行った記憶もありません。そのような幼少期を過ごした自分が父と同じ畜産に携わっている今、私が目指す「牛飼い」というものには強い信念があります。</p>
            <p className="margin-bottom10">きちんと休みを取るということです。</p>
            <p className="margin-bottom10">餌やりを含む作業の徹底的なマニュアル化を実現させ、長年の経験を必要としていた「肥育」という技術を、だれでも簡単に出来るような飼養管理に変化させています。そうすることで、365日休みなく働くのではなく1週間に1回は必ず休みを取ることが出来ます。農場の労働時間も1日8時間と決めており、ほとんど残業することはありません。</p>
            <p className="margin-bottom10">獣医師としても「農場内で牛達を絶対に死なせない」という信念があります。勿論、最後は食肉処理場で屠畜されるわけですが、必ず家畜としての使命を全うさせ、安心安全で美味しいお肉として皆様のもとに届けることを目標としています。農場内の作業は楽とは言えませんが、スタッフを始め毎日充実した仕事をしているという自負はあります。</p>
            <p className="margin-bottom10">スタッフさんへの福利厚生も含めた待遇を毎年改善し、自信を持って次世代へと事業継承できる日本一の肥育農場を目指しています。</p>
          </div>
        </section>

        <section className="index-section" id="jyusyourireki">
          <div className="content">
            <div className="centering-container text-center margin-top20">
              <h2 className="color-h2-text color-h2-border">受賞履歴</h2>
            </div>
          </div>
        </section>

        <section className="index-section">
          <div className="content">
            <div className="centering-container">
              <table className="margin-top20 colored prize">
                <tbody>
                  <tr className="noBgColor">
                    <td className="text-bold text-xlargee" colSpan="3">2020年</td>
                  </tr>
                  {/* <tr>
                    <td>2020年<br className="isShown"/>1月</td><td>第11回配合飼料和牛枝肉研究会　<br className="isShown"/>銅賞</td>
                  </tr>
                  <tr>
                    <td>2020年<br className="isShown"/>2月</td><td>第39回全農肉牛枝肉共励会　<br className="isShown"/>銀賞</td>
                  </tr>
                  <tr>
                    <td>2020年<br className="isShown"/>3月</td><td>第18回JA菊池肉牛青年部枝肉共励会　<br className="isShown"/>銀賞</td>
                  </tr>
                  <tr>
                    <td>2020年<br className="isShown"/>3月</td><td>第21回JA菊池黒毛和種研究会枝肉研修会　<br className="isShown"/>銅賞</td>
                  </tr>
                  <tr>
                    <td>2020年<br className="isShown"/>7月</td><td>第32回JA菊池肉牛枝肉共励会　<br className="isShown"/>グランドチャンピオン<br/><a className="link-text" href="https://jakikuchi.jp/4478.html">きくちのまんま新聞</a></td>
                  </tr>
                  <tr>
                    <td>2020年<br className="isShown"/>9月</td><td>第44回九州管内系統和牛枝肉共励会　<br className="isShown"/>銀賞1席</td>
                  </tr>
                  <tr>
                    <td>2020年<br className="isShown"/>11月</td><td>第32回JA熊本経済連黒毛和牛勉強会　<br className="isShown"/>金賞</td>
                  </tr> */}
                  <tr>
                    <td className="text-right month">1月</td><td>第11回<br className="isShown"/>配合飼料和牛枝肉研究会</td><td className="jyuni">銅賞</td>
                  </tr>
                  <tr>
                    <td className="text-right month">2月</td><td>第39回<br className="isShown"/>全農肉牛枝肉共励会</td><td className="jyuni">銀賞</td>
                  </tr>
                  <tr>
                    <td className="text-right month">3月</td><td>第18回<br className="isShown"/>JA菊池肉牛青年部枝肉共励会</td><td className="jyuni">銀賞</td>
                  </tr>
                  <tr>
                    <td className="text-right month">3月</td><td>第21回<br className="isShown"/>JA菊池黒毛和種研究会枝肉研修会</td><td className="jyuni">銅賞</td>
                  </tr>
                  <tr className="text-highlighted">
                    <td className="text-right month">7月</td><td>第32回<br className="isShown"/>JA菊池肉牛枝肉共励会<br/><a className="link-text text-small" href="https://jakikuchi.jp/4478.html">きくちのまんま新聞<i class="material-icons text-small">
link
</i></a></td><td className="jyuni text-highlighted">グランドチャンピオン</td>
                  </tr>
                  <tr>
                    <td className="text-right month">9月</td><td>第44回<br className="isShown"/>九州管内系統和牛枝肉共励会</td><td className="jyuni">銀賞1席</td>
                  </tr>
                  <tr className="text-highlighted">
                    <td className="text-right month">11月</td><td>第32回<br className="isShown"/>JA熊本経済連黒毛和牛勉強会</td><td className="jyuni text-highlighted">金賞</td>
                  </tr>

                  <tr className="noBgColor">
                    <td className="text-bold text-xlargee"  colSpan="3">2021年</td>
                  </tr>

                  {/* <tr>
                    <td>2021年<br className="isShown"/>3月</td><td>第19回JA菊池肉牛青年部枝肉共励会　<br className="isShown"/>グランドチャンピオン</td>
                  </tr>
                  <tr>
                    <td>2021年<br className="isShown"/>3月</td><td>第22回JA菊池黒毛和種研究会枝肉研修会　<br className="isShown"/>銀賞1席</td>
                  </tr>
                  <tr>
                    <td>2021年<br className="isShown"/>4月</td><td>第33回JA熊本経済連黒毛和牛勉強会　<br className="isShown"/>銅賞</td>
                  </tr> */}
                  <tr className="text-highlighted">
                    <td className="text-right month">3月</td><td>第19回<br className="isShown"/>JA菊池肉牛青年部枝肉共励会</td><td className="jyuni">グランドチャンピオン</td>
                  </tr>
                  <tr>
                    <td className="text-right month">3月</td><td>第22回<br className="isShown"/>JA菊池黒毛和種研究会枝肉研修会</td><td className="jyuni">銀賞1席</td>
                  </tr>
                  <tr>
                    <td className="text-right month">4月</td><td>第33回<br className="isShown"/>JA熊本経済連黒毛和牛勉強会</td><td className="jyuni">銅賞</td>
                  </tr>

                  <tr>
                    <td className="text-right month">6月</td><td>令和3年度<br className="isShown"/>南港市場系統農協肉牛枝肉研究会</td><td className="jyuni">優良賞</td>
                  </tr>
                  <tr>
                    <td className="text-right month">7月</td><td>第33回<br className="isShown"/>JA菊池肉牛枝肉共励会</td><td className="jyuni">銀賞2席</td>
                  </tr>
                  <tr>
                    <td className="text-right month">12月</td><td>第34回<br className="isShown"/>JA熊本経済連黒毛和牛勉強会</td><td className="jyuni">銀賞</td>
                  </tr>
                  <tr className="text-highlighted">
                    <td className="text-right month">12月</td><td>第27回<br className="isShown"/>熊本経済連肉牛枝肉共励会</td><td className="jyuni">金賞</td>
                  </tr>

                  <tr className="noBgColor">
                    <td className="text-bold text-xlargee"  colSpan="3">2022年</td>
                  </tr>

                  <tr>
                    <td className="text-right month">3月</td><td>	第23回<br className="isShown"/>JA菊池黒毛和種研究会枝肉研修会</td><td className="jyuni">銀賞1席</td>
                  </tr>
                  <tr>
                    <td className="text-right month">6月</td><td>第16回<br className="isShown"/>熊本経済連 肉牛枝肉販売促進会</td><td className="jyuni">銀賞1席</td>
                  </tr>
                  <tr>
                    <td className="text-right month">11月</td><td>第29回<br className="isShown"/>JA熊本経済連 肉畜枝肉共励会</td><td className="jyuni">銅賞</td>
                  </tr>
                  <tr>
                    <td className="text-right month">12月</td><td>第36回<br className="isShown"/>JA熊本経済連黒毛和牛勉強会</td><td className="jyuni">銀賞</td>
                  </tr>
                  <tr></tr>{/*背景色の位置を合わせるためのダミー*/}

                  <tr className="noBgColor">
                    <td className="text-bold text-xlargee"  colSpan="3">2023年</td>
                  </tr>

                  <tr>
                    <td className="text-right month">3月</td><td>	第24回<br className="isShown"/>JA菊池黒毛和種研究会枝肉研修会</td><td className="jyuni">銀賞2席</td>
                  </tr>
                  <tr>
                    <td className="text-right month">4月</td><td>第37回<br className="isShown"/>JA熊本経済連黒毛和牛勉強会</td><td className="jyuni">銅賞</td>
                  </tr>
                  <tr className="text-highlighted">
                    <td className="text-right month">6月</td><td>第17回<br className="isShown"/>熊本経済連 肉牛枝肉販売促進会</td><td className="jyuni text-highlighted">グランドチャンピオン</td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </section>

        {/*<section className="index-section" id="jyusyourireki">
          <div className="content">
            <div className="centering-container text-center margin-top20">
              <h2 className="color-h2-text color-h2-border">取引先</h2>
            </div>
          </div>
        </section>

        <section className="index-section">
          <div className="content">
            <div className="centering-container">
              <p className="margin-bottom10">取引先を記入</p>
            </div>
          </div>
        </section>

        <section className="index-section" id="jyusyourireki">
          <div className="content">
            <div className="centering-container text-center margin-top20">
              <h2 className="color-h2-text color-h2-border">消費者の食卓まで</h2>
            </div>
          </div>
        </section>

        <section className="index-section">
          <div className="content">
            <div className="centering-container">
              <p className="margin-bottom10">流れを記入</p>
            </div>
          </div>
        </section> */}

        <section className="index-section" id="yakuin">
          <div className="content">
            <div className="centering-container text-center margin-top50">
              <h2 className="color-h2-text color-h2-border">役員略歴</h2>
            </div>
          </div>
        </section>


        <section className="index-section">
          <div className="baseBox">
            <img src={picSyachou} alt="代表取締役"/>
          </div>
          <div className="content">
            <div className="centering-container margin-top20">
              <h3>代表取締役：佐々隆文</h3>
              <table className="colored">
                <tbody>
                  <tr>
                    <td>1980年</td><td>熊本県菊池市に生まれる</td>
                  </tr>
                  <tr>
                    <td>2004年</td><td>北里大学獣医畜産学部獣医学科を卒業<br/>獣医師免許取得</td>
                  </tr>
                  <tr>
                    <td>2004年</td><td>(有)シェパード中央家畜診療所へ入社<br/>6年間獣医師として肉牛の診療に従事</td>
                  </tr>
                  <tr>
                    <td>2010年</td><td>佐々畜産に就農し、ルーメン家畜診療所を<br className="isShown"/>開設</td>
                  </tr>
                  <tr>
                    <td>2018年</td><td>株式会社佐々畜産を設立</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>

        <section className="index-section">
          <div className="baseBox">
            <img src={picTorishimariyaku} alt="取締役"/>
          </div>
          <div className="content">
            <div className="centering-container margin-top20">
              <h3>取締役：佐々有紀</h3>
              <table className="colored">
                <tbody>
                  <tr>
                    <td>1977年</td><td>愛知県生まれ</td>
                  </tr>
                  <tr>
                    <td>2002年</td><td>北里大学獣医畜産学部獣医学科卒業<br/>獣医師免許取得</td>
                  </tr>
                  <tr>
                    <td>2006年</td><td>名古屋大学大学院医学系研究科付属<br/>医学教育研究支援センター 実験動物部門<br/>技術職員</td>
                  </tr>
                  <tr>
                    <td>2008年</td><td>鹿児島県阿久根食肉衛生検査所<br/>嘱託職員</td>
                  </tr>
                  <tr>
                    <td>2010年</td><td>ルーメン家畜診療所 及び、<br/>熊本県食肉衛生検査所<br/>嘱託職員</td>
                  </tr>
                  <tr>
                    <td>2018年</td><td>現職</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>

        <section className="index-section">
          <div className="content">
            <div className="centering-container text-center margin-top50">
              <h2 className="color-h2-text color-h2-border">スタッフ紹介</h2>
            </div>
          </div>
        </section>

        <section className="index-section">
          <div className="baseBox" id="">
            <img src={picJyugyoin2} alt="農場長"/>
          </div>
          <div className="content">
            <div className="centering-container">
              <h3>農場長：大塚悠弘</h3>
              <p>「挑戦」<br/>チャレンジ精神を忘れず、日々の作業一つ一つにプロフェッショナルであり続け、人工知能（U-motion）を駆使した次世代肥育の頂点を目指す！</p>
            </div>
          </div>
        </section>

        <section className="index-section">
          <div className="baseBox" id="">
            <img src={picJyugyoin3} alt="従業員"/>
          </div>
          <div className="content">
            <div className="centering-container">
              <h3>森野祐子</h3>
              <p>牛の事が大好きです。牛とともに過ごし、牛とともに成長し、大切な命を一番に輝かせられるように努めていきます。</p>
            </div>
          </div>
        </section>

        <section className="index-section">
          <div className="baseBox" id="">
            <img src={picJyugyoin1} alt="卒業生"/>
          </div>
          <div className="content">
            <div className="centering-container margin-top20">
              <h3>卒業生：田久保光太郎</h3>
              <p>はじめまして。佐々畜産の繁殖担当、田久保光太郎です。
学生時代の搾乳のバイトから始まり、畜産の世界にハマり、現在に至ります。サラリーマン家庭で育ちましたが、牛が大好きです。
将来の夢である独立の為、日々勉強中です。どうぞ宜しくお願いします。</p>
              <table className="td-about text-highlighted">
                <tbody>
                  <tr>
                    <td>2013年&nbsp;&nbsp;&nbsp;9月</td><td>入社</td>
                  </tr>
                  <tr>
                    <td>2021年&nbsp;12月</td><td>卒業</td>
                  </tr>
                  <tr>
                    <td>2022年&nbsp;&nbsp;&nbsp;1月</td><td>繁殖農家として独立</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>

        <section className="index-section">
          <div className="content">
            <div className="centering-container text-center margin-top50">
              <h2 className="color-h2-text color-h2-border">従業員・研修生の募集</h2>
              <p>佐々畜産では従業員・研修生を募集しております。詳しくは下記リンクよりご確認ください。</p>
              <Link to="/recruit" className="button btn-sticky">従業員・研修生の募集</Link>
            </div>
          </div>
        </section>

        {/* <section className="index-section">
          <div className="content">
            <div className="centering-container">
              <p>佐々畜産では従業員・研修生を募集しております。詳しくは下記リンクよりご確認ください。</p>
              <Link to="/recruit" className="button btn-sticky">従業員・研修生の募集</Link>
            </div>
          </div>
        </section> */}
        
        {/* <section className="index-section">
          <div className="content">
            <div className="centering-container text-center margin-top50">
              <h2 className="color-h2-text color-h2-border">関連リンク</h2>
            </div>
          </div>
        </section> */}
        
        {/* <section className="index-section">
          <div className="content">
            <div className="centering-container margin-top20">
              <table className="colored">
                <tbody>
                  <tr>
                    <td><a href="https://www.shepherd-clc.com">(有)シェパード中央家畜診療所</a></td><td></td>
                  </tr>
                  <tr>
                    <td><a href="http://labogenitor.com">株式会社ラボジェネター</a></td><td>飼料の製造・販売</td>
                  </tr>
                  <tr>
                    <td><a href="https://www.desamis.co.jp">デザミス株式会社</a></td><td>U-motion</td>
                  </tr>
                  <tr>
                    <td><a href="http://gyuonkei.jp">モバイル牛温恵</a></td><td>分娩監視システム</td>
                  </tr>
                  <tr>
                    <td><a href="https://chuugoku-bussan.jp">中国物産株式会社</a></td><td>牛用飼料の工場</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
		    </section> */}

      </div>
  	</Layout>
	)
}

export default About
